
import Layout from '../Layout';
import './App.css';


function App() {
  return (

    <div className="App">

      <Layout/>
      
    </div>
  );
}

export default App;
